import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap, Power2 } from 'gsap'
import barba from '@barba/core'

import CameraControls from 'camera-controls'
CameraControls.install( { THREE: THREE } )


// pagina about
// import * as dat from 'dat.gui'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'
// pagina about

// pagina world
import Stats from "three/examples/jsm/libs/stats.module";
import {Octree} from "three/examples/jsm/math/Octree";
import {Capsule} from "three/examples/jsm/math/Capsule";
// pagina world

barba.init({
    views: [{
        namespace: 'home',
        beforeEnter() {
            // update the menu based on user navigation
            // menu.update();
            init()
        },
        afterEnter() {
            // refresh the parallax based on new page content
            // parallax.refresh();
        }
    },{
        namespace: 'about',
        beforeEnter() {
            // update the menu based on user navigation
            // menu.update();
            about()
        },
        afterEnter() {
            // refresh the parallax based on new page content
            // parallax.refresh();
            // const element = document.querySelector('div.dg');
            // if(element) {
            //     element.remove();
            // }
        }
    },{
        namespace: 'world',
        beforeEnter() {
            // update the menu based on user navigation
            // menu.update();
            world()
        },
        afterEnter() {
            // refresh the parallax based on new page content
            // parallax.refresh();
            // const element = document.querySelector('div.dg');
            // if(element) {
            //     element.remove();
            // }
        }
    }],
    transitions: [{
        name: 'opacity-transition',
        leave(data) {
            return gsap.to(data.current.container, {
                opacity: 0
            });
        },
        enter(data) {
            return gsap.from(data.next.container, {
                opacity: 0
            });
        }
    }]
});

const init = () => {

    /**
     * Loaders
     */

    const loadingBarElement = document.querySelector('.loading-bar')

    let sceneReady = false
    const loadingManager = new THREE.LoadingManager(
        // Loaded
        () =>
        {
            // Wait a little
            window.setTimeout(() =>
            {
                // Animate overlay
                gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

                // Update loadingBarElement
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            }, 500)

            window.setTimeout(() =>
            {
                sceneReady = true
            }, 2000)
        },

        // Progress
        (itemUrl, itemsLoaded, itemsTotal) =>
        {
            // Calculate the progress and update the loadingBarElement
            const progressRatio = itemsLoaded / itemsTotal
            loadingBarElement.style.transform = `scaleX(${progressRatio})`
        }
    )
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco/')

    const gltfLoader = new GLTFLoader(loadingManager)
    gltfLoader.setDRACOLoader(dracoLoader)


    const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)


    /**
     * Base
     */
    // Debug
    const debugObject = {}

    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    //     const scene = new THREE.Scene()
    const scene = new THREE.Scene();
    {
        const color = 0xffffff;  // white
        const near = 4;
        const far = 60;
        // scene.fog = new THREE.Fog(color, near, far);
    }

    /**
     * Overlay
     */
    const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
    const overlayMaterial = new THREE.ShaderMaterial({
        // wireframe: true,
        transparent: true,
        uniforms:
            {
                uAlpha: { value: 1 }
            },
        vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
        fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
    })
    const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
    scene.add(overlay)

    /**
     * Update all materials
     */
    const updateAllMaterials = () =>
    {
        scene.traverse((child) =>
        {
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                // child.material.envMap = environmentMap
                child.material.envMapIntensity = debugObject.envMapIntensity
                child.material.needsUpdate = true
                child.castShadow = true
                child.receiveShadow = true

                // child.depthWrite = false
                // child.depthTest = true
            }
        })
    }

    /**
     * Environment map
     */
    const environmentMap = cubeTextureLoader.load([
        './textures/environmentMaps/0/px.jpg',
        './textures/environmentMaps/0/nx.jpg',
        './textures/environmentMaps/0/py.jpg',
        './textures/environmentMaps/0/ny.jpg',
        './textures/environmentMaps/0/pz.jpg',
        './textures/environmentMaps/0/nz.jpg'
    ])

    environmentMap.encoding = THREE.sRGBEncoding

    // azul
    scene.background = new THREE.Color( 0x88ccee );
    // scene.background = new THREE.Color( 0x16C188 );
    scene.fog = new THREE.Fog( 0x88ccee, 38, 50 );
    // scene.background = environmentMap
    // scene.environment = environmentMap

    debugObject.envMapIntensity = 5

    /**
     * Models
     */
    gltfLoader.load(
        './models/brasilia.glb',
        (gltf) =>
        {
            gltf.scene.scale.set(1, 1, 1)
            gltf.scene.rotation.y = Math.PI * 0.5
            // gltf.scene.castShadow = true
            // gltf.scene.receiveShadow = true
            scene.add(gltf.scene)

            updateAllMaterials()
        }
    )

    // Graves
    const graves = new THREE.Group()
    scene.add(graves)

    // const graveGeometry = new THREE.BoxGeometry(0.6, 0.8, 0.1)
    const graveGeometry = new THREE.IcosahedronGeometry(0.35, 0)
    const graveMaterial = new THREE.MeshStandardMaterial({ color: '#DC542C' })

    for(let i = 0; i < 40; i++)
    {
        const angle = Math.random() * Math.PI * 2 // Random angle
        const radius = 6 + Math.random() * 16      // Random radius
        const x = Math.cos(angle) * radius        // Get the x position using cosinus
        const z = Math.sin(angle) * radius        // Get the z position using sinus

        // Create the mesh
        const grave = new THREE.Mesh(graveGeometry, graveMaterial)
        grave.castShadow = true

        // Position
        grave.position.set(x, 0.3, z)

        // Rotation
        grave.rotation.z = (Math.random() - 0.5) * 0.4
        grave.rotation.y = (Math.random() - 0.5) * 0.4

        // Add to the graves container
        graves.add(grave)
    }


    // terrain
    // texture loader
    const loader = new THREE.TextureLoader()
    const height = loader.load('/img/height.png')
    const texture = loader.load('/img/texture.jpg')
    const alpha = loader.load('/img/alpha.png')

    // Objects
    const terrainGeometry = new THREE.PlaneBufferGeometry(100, 100, 64, 64);

    // Materials
    const terrainMaterial = new THREE.MeshStandardMaterial({
        color: '#129B6D',
        map: texture,
        displacementMap: height,
        displacementScale: .9,
        alphaMap: alpha,
        transparent: false,
        depthTest: true
    })

    // Mesh
    const terrain = new THREE.Mesh(terrainGeometry, terrainMaterial);
    // terrain.rotation.x = - Math.PI * 0.40;
    terrain.rotation.x = - Math.PI * 0.5;
    terrain.position.y = -0.8;
    // scene.add(terrain);

    document.addEventListener('mousemove', animateTerrain);

    let mouseY = 0;
    let mouseX = 0;

    function animateTerrain(event) {
        mouseY = event.clientY;
        mouseX = event.clientX;
    }


    /**
     * Points of interest
     */
    const raycaster = new THREE.Raycaster()
    const points = [
        // point 0
        {
            position: new THREE.Vector3(1.55, 0.3, - 0.6),
            element: document.querySelector('.point-0')
        },
        // point 1
        {
            position: new THREE.Vector3(0.5, 0.8, - 1.6),
            element: document.querySelector('.point-1')
        },
        // point 2
        {
            position: new THREE.Vector3(1.6, 1.3, 2.7),
            element: document.querySelector('.point-2')
        },
        // point 3
        {
            position: new THREE.Vector3(0.5, 0.3, 2.0),
            element: document.querySelector('.point-3')
        },
        // point 4
        {
            position: new THREE.Vector3(-4.5, 0.3, - 0.5),
            element: document.querySelector('.point-4')
        },
        // point 5
        {
            position: new THREE.Vector3(-4.0, 0.3, - 1.6),
            element: document.querySelector('.point-5')
        },
        // point 6
        {
            position: new THREE.Vector3(-2.0, 0.3, - 3.6),
            element: document.querySelector('.point-6')
        },
        // point 7
        {
            position: new THREE.Vector3(-1.5, 0.3, - 3.0),
            element: document.querySelector('.point-7')
        },
    ]

    /**
     * Lights
     */
    // const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
    // directionalLight.castShadow = true
    // directionalLight.shadow.camera.far = 15
    // directionalLight.shadow.mapSize.set(1024, 1024)
    // directionalLight.shadow.normalBias = 0.05
    // directionalLight.position.set(0.25, 3, - 2.25)
    // scene.add(directionalLight)

    const fillLight1 = new THREE.HemisphereLight( 0x4488bb, 0x002244, 0.5 );
    fillLight1.position.set( 2, 1, 1 );
    scene.add( fillLight1 );

    const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.8 );
    directionalLight.position.set( - 5, 25, - 1 );
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.near = 0.01;
    directionalLight.shadow.camera.far = 500;
    directionalLight.shadow.camera.right = 30;
    directionalLight.shadow.camera.left = - 30;
    directionalLight.shadow.camera.top	= 30;
    directionalLight.shadow.camera.bottom = - 30;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    directionalLight.shadow.radius = 4;
    directionalLight.shadow.bias = - 0.00006;
    scene.add( directionalLight );

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    /**
     * Camera
     */
// Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
    camera.position.set(12, 5, 1)
    scene.add(camera)

// Controls
//     const controls = new OrbitControls(camera, canvas)
    // controls.enableDamping = true

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true,
        // logarithmicDepthBuffer: true
    })
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    // renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 2
    renderer.shadowMap.enabled = true
    // renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.shadowMap.type = THREE.VSMShadowMap;
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


    /**
     * Camera Controls
     */
    const cameraControls = new CameraControls( camera, renderer.domElement );
    cameraControls.minZoom = 0.9;
    cameraControls.maxZoom = 4
    cameraControls.minDistance = 8
    cameraControls.maxDistance = 38
    cameraControls.rotateSpeed = 0.07

    cameraControls.maxPolarAngle = Math.PI * 0.465;
    cameraControls.minPolarAngle = Math.PI * 0.05;
    cameraControls.dampingFactor = 0.03;
    // cameraControls.minAzimuthAngle = Math.PI;
    // cameraControls.mouseButtons.left = CameraControls.ACTION.NONE


    const clock = new THREE.Clock();

    // movimentos camera
    const pos1 = () => {
        // cameraControls.setLookAt( 1, 2, 1, 1, 1, -10, true )
        cameraControls.mouseButtons.left = CameraControls.ACTION.NONE
        cameraControls.setPosition( 0, 28, 0, true )
        cameraControls.rotateTo( Math.PI * 0.5, 0, true )
        setBoundary1()

    }

    const pos2 = () => {
        cameraControls.setPosition( -5, 2, 1, true )
    }

    const pos3 = () => {
        cameraControls.setLookAt( 1, 2, 3, 1, 1, 0, true )
    }

    const resetPos = () => {
        cameraControls.reset(true)
        cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE
        setBoundary0()
    }

    document.querySelector('#pos1').addEventListener('click',  pos1);

    document.querySelector('#pos2').addEventListener('click',  pos2);

    document.querySelector('#pos3').addEventListener('click',  pos3);

    document.querySelector('#reset').addEventListener('click',  resetPos);



    // boundaries
    const bbHelper = new THREE.Mesh(
        new THREE.BoxGeometry( 1, 1, 1 ),
        new THREE.MeshBasicMaterial( { color: 0xff0000, wireframe: true } )
    );
    scene.add( bbHelper );
    bbHelper.visible = false;

    function setBoundary0 () {
        cameraControls.setBoundary( null );
        bbHelper.visible = false;
    }

    function setBoundary1 () {
        const bb = new THREE.Box3(
            new THREE.Vector3( -5.0, -5.0, -5.0 ),
            new THREE.Vector3( 5.0, 5.0, 5.0 )
        );
        cameraControls.setBoundary( bb );

        bbHelper.position.set( 0, 0, 0 );
        bbHelper.scale.set( 10, 10, 10 );
        bbHelper.visible = true;
    }

    function setBoundary2 () {
        const bb = new THREE.Box3(
            new THREE.Vector3( -20, 0, -20 ),
            new THREE.Vector3( -10, 10, -10 )
        );
        cameraControls.setBoundary( bb );

        bbHelper.position.set( -15, 5, -15 );
        bbHelper.scale.set( 10, 10, 10 );
        bbHelper.visible = true;
    }

    function setBoundary3 () {
        const bb = new THREE.Box3(
            new THREE.Vector3( -14, -1, -13 ),
            new THREE.Vector3( 10, 1, 13 )
        );
        cameraControls.setBoundary( bb );

        bbHelper.position.set( 0, 0, 0 );
        bbHelper.scale.set( 20, 20, 20 );
        bbHelper.visible = false;
    }
    // setBoundary3();

    document.querySelector('#setB1').addEventListener('click',  setBoundary3);
    document.querySelector('#resetB1').addEventListener('click',  setBoundary0);


    /**
     * Animate
     */
    const tick = () =>
    {
        // Update controls
        // controls.update()

        // Update camera controls
        const delta = clock.getDelta()
        const hasControlsUpdated = cameraControls.update( delta )


        terrain.rotation.z = .02 * delta;
        terrain.material.displacementScale = .3 + mouseY * 0.0005;

        terrain.rotation.z = mouseX * 0.00005;

        // Update points only when the scene is ready
        if(sceneReady)
        {
            // Go through each point
            for(const point of points)
            {
                // Get 2D screen position
                const screenPosition = point.position.clone()
                screenPosition.project(camera)

                // Set the raycaster
                raycaster.setFromCamera(screenPosition, camera)
                const intersects = raycaster.intersectObjects(scene.children, true)

                // No intersect found
                if(intersects.length === 0)
                {
                    // Show
                    point.element.classList.add('visible')
                }

                // Intersect found
                else
                {
                    // Get the distance of the intersection and the distance of the point
                    const intersectionDistance = intersects[0].distance
                    const pointDistance = point.position.distanceTo(camera.position)

                    // Intersection is close than the point
                    if(intersectionDistance < pointDistance)
                    {
                        // Hide
                        point.element.classList.remove('visible')
                    }
                    // Intersection is further than the point
                    else
                    {
                        // Show
                        point.element.classList.add('visible')
                    }
                }

                const translateX = screenPosition.x * sizes.width * 0.5
                const translateY = - screenPosition.y * sizes.height * 0.5
                point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
            }
        }

        // Render
        renderer.render(scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()

    // function onMouseMove(e) {
    //     const x = e.clientX;
    //     const y = e.clientY;
    //
    //     gsap.to(scene.rotation, {
    //         y: gsap.utils.mapRange(0, window.innerWidth, 0.2, -0.2, x),
    //         x: gsap.utils.mapRange(0, window.innerHeight, 0.2, -0.2, y),
    //     })
    // }
    // window.addEventListener( 'mousemove', onMouseMove);


}


const about = () => {

    /**
     * Loaders
     */

    const loadingBarElement = document.querySelector('.loading-bar-about')

    let sceneReady = false
    const loadingManager = new THREE.LoadingManager(
        // Loaded
        () =>
        {
            // Wait a little
            window.setTimeout(() =>
            {
                // Animate overlay
                gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

                // Update loadingBarElement
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            }, 500)

            window.setTimeout(() =>
            {
                sceneReady = true
            }, 2000)
        },

        // Progress
        (itemUrl, itemsLoaded, itemsTotal) =>
        {
            // Calculate the progress and update the loadingBarElement
            const progressRatio = itemsLoaded / itemsTotal
            loadingBarElement.style.transform = `scaleX(${progressRatio})`
        }
    )


    /**
     * Base
     */
// Debug
//     const gui = new dat.GUI()

// Canvas
    const canvas = document.querySelector('canvas.webgl-about')

// Scene
    const scene = new THREE.Scene()


    /**
     * Overlay
     */
    const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
    const overlayMaterial = new THREE.ShaderMaterial({
        // wireframe: true,
        transparent: true,
        uniforms:
            {
                uAlpha: { value: 1 }
            },
        vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
        fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
    })
    const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
    scene.add(overlay)


    /**
     * Loaders
     */
    const gltfLoader = new GLTFLoader(loadingManager)
    const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
    const textureLoader = new THREE.TextureLoader(loadingManager)

    /**
     * Update all materials
     */
    const updateAllMaterials = () =>
    {
        scene.traverse((child) =>
        {
            if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
            {
                child.material.envMapIntensity = 5
                child.material.needsUpdate = true
                child.castShadow = true
                child.receiveShadow = true
            }
        })
    }

    /**
     * Environment map
     */
    const environmentMap = cubeTextureLoader.load([
        './textures/environmentMaps/0/px.jpg',
        './textures/environmentMaps/0/nx.jpg',
        './textures/environmentMaps/0/py.jpg',
        './textures/environmentMaps/0/ny.jpg',
        './textures/environmentMaps/0/pz.jpg',
        './textures/environmentMaps/0/nz.jpg'
    ])
    environmentMap.encoding = THREE.sRGBEncoding

    scene.background = environmentMap
    scene.environment = environmentMap

    /**
     * Models
     */
    gltfLoader.load(
        './models/DamagedHelmet/glTF/DamagedHelmet.gltf',
        (gltf) =>
        {
            gltf.scene.scale.set(2, 2, 2)
            gltf.scene.rotation.y = Math.PI * 0.5
            scene.add(gltf.scene)

            updateAllMaterials()
        }
    )

    /**
     * Lights
     */
    const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(1024, 1024)
    directionalLight.shadow.camera.far = 15
    directionalLight.shadow.normalBias = 0.05
    directionalLight.position.set(0.25, 3, - 2.25)
    scene.add(directionalLight)

    /**
     * Sizes
     */
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight

        // Update camera
        camera.aspect = sizes.width / sizes.height
        camera.updateProjectionMatrix()

        // Update renderer
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

        // Update effect composer
        effectComposer.setSize(sizes.width, sizes.height)
    })

    /**
     * Camera
     */
// Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.position.set(4, 1, - 4)
    scene.add(camera)

// Controls
    const controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    })
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFShadowMap
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.toneMappingExposure = 1.5
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    /**
     * Post processing
     */
    let RenderTargetClass = null;

    if(renderer.getPixelRatio() === 1 && renderer.capabilities.isWebGL2)
    {
        RenderTargetClass = THREE.WebGLMultisampleRenderTarget
        console.log('Using WebGLMultisampleRenderTarget')
    }
    else
    {
        RenderTargetClass = THREE.WebGLRenderTarget
        console.log('Using WebGLRenderTarget')
    }

    const renderTarget = new RenderTargetClass(
        800,
        600,
        {
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            encoding: THREE.sRGBEncoding
        }
    );

// Effect composer
    const effectComposer = new EffectComposer(renderer, renderTarget)
    effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    effectComposer.setSize(sizes.width, sizes.height)

// Render pass
    const renderPass = new RenderPass(scene, camera)
    effectComposer.addPass(renderPass)

// Dot screen pass
    const dotScreenPass = new DotScreenPass()
    dotScreenPass.enabled = false
    effectComposer.addPass(dotScreenPass)

// Glitch pass
    const glitchPass = new GlitchPass()
    glitchPass.goWild = true
    glitchPass.enabled = false
    effectComposer.addPass(glitchPass)

// RGB Shift pass
    const rgbShiftPass = new ShaderPass(RGBShiftShader)
    rgbShiftPass.enabled = false
    effectComposer.addPass(rgbShiftPass)

// Antialias pass
    if(renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2)
    {
        const smaaPass = new SMAAPass()
        effectComposer.addPass(smaaPass)

        console.log('Using SMAA')
    }

// Unreal Bloom pass
    const unrealBloomPass = new UnrealBloomPass()
    unrealBloomPass.enabled = false
    effectComposer.addPass(unrealBloomPass)

    unrealBloomPass.strength = 0.3
    unrealBloomPass.radius = 1
    unrealBloomPass.threshold = 0.6

    // gui.add(unrealBloomPass, 'enabled')
    // gui.add(unrealBloomPass, 'strength').min(0).max(2).step(0.001)
    // gui.add(unrealBloomPass, 'radius').min(0).max(2).step(0.001)
    // gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.001)

// Tint pass
    const TintShader = {
        uniforms:
            {
                tDiffuse: { value: null },
                uTint: { value: null }
            },
        vertexShader: `
        varying vec2 vUv;

        void main()
        {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

            vUv = uv;
        }
    `,
        fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform vec3 uTint;

        varying vec2 vUv;

        void main()
        {
            vec4 color = texture2D(tDiffuse, vUv);
            color.rgb += uTint;

            gl_FragColor = color;
        }
    `
    }

    const tintPass = new ShaderPass(TintShader)
    tintPass.material.uniforms.uTint.value = new THREE.Vector3()
    effectComposer.addPass(tintPass)

    // gui.add(tintPass.material.uniforms.uTint.value, 'x').min(- 1).max(1).step(0.001).name('red')
    // gui.add(tintPass.material.uniforms.uTint.value, 'y').min(- 1).max(1).step(0.001).name('green')
    // gui.add(tintPass.material.uniforms.uTint.value, 'z').min(- 1).max(1).step(0.001).name('blue')

// Displacement pass
    const DisplacementShader = {
        uniforms:
            {
                tDiffuse: { value: null },
                uTime: { value: null },
                uNormalMap: { value: null }
            },
        vertexShader: `
        varying vec2 vUv;

        void main()
        {
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

            vUv = uv;
        }
    `,
        fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float uTime;
        uniform sampler2D uNormalMap;

        varying vec2 vUv;

        void main()
        {
            vec3 normalColor = texture2D(uNormalMap, vUv).xyz * 2.0 - 1.0;
            vec2 newUv = vUv + normalColor.xy * 0.1;
            vec4 color = texture2D(tDiffuse, newUv);

            vec3 lightDirection = normalize(vec3(- 1.0, 1.0, 0.0));
            float lightness = clamp(dot(normalColor, lightDirection), 0.0, 1.0);
            color.rgb += lightness * 2.0;

            gl_FragColor = color;
        }
    `
    }

    const displacementPass = new ShaderPass(DisplacementShader)
    displacementPass.material.uniforms.uTime.value = 0
    displacementPass.material.uniforms.uNormalMap.value = textureLoader.load('./textures/interfaceNormalMap.png')
    // effectComposer.addPass(displacementPass)

    /**
     * Animate
     */
    const clock = new THREE.Clock()

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()

        // Update passes
        displacementPass.material.uniforms.uTime.value = elapsedTime

        // Update controls
        controls.update()

        // Render
        // renderer.render(scene, camera)
        effectComposer.render()

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()


}


const world = () => {


    /**
     * Loaders
     */

    const loadingBarElement = document.querySelector('.loading-bar-world')

    let sceneReady = false
    const loadingManager = new THREE.LoadingManager(
        // Loaded
        () =>
        {
            // Wait a little
            window.setTimeout(() =>
            {
                // Animate overlay
                gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

                // Update loadingBarElement
                loadingBarElement.classList.add('ended')
                loadingBarElement.style.transform = ''
            }, 500)

            window.setTimeout(() =>
            {
                sceneReady = true
            }, 2000)
        },

        // Progress
        (itemUrl, itemsLoaded, itemsTotal) =>
        {
            // Calculate the progress and update the loadingBarElement
            const progressRatio = itemsLoaded / itemsTotal
            loadingBarElement.style.transform = `scaleX(${progressRatio})`
        }
    )


    // const GUI = new dat.GUI()
    const clock = new THREE.Clock();
    const scene = new THREE.Scene();


    /**
     * Overlay
     */
    const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
    const overlayMaterial = new THREE.ShaderMaterial({
        // wireframe: true,
        transparent: true,
        uniforms:
            {
                uAlpha: { value: 1 }
            },
        vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
        fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
    })
    const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
    scene.add(overlay)


    /**
     * Loaders
     */
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco/')

    const gltfLoader = new GLTFLoader(loadingManager)
    gltfLoader.setDRACOLoader(dracoLoader)

    const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
    const textureLoader = new THREE.TextureLoader(loadingManager)




    scene.background = new THREE.Color( 0x88ccee );
    scene.fog = new THREE.Fog( 0x88ccee, 0, 50 );

    const camera = new THREE.PerspectiveCamera( 80, window.innerWidth / window.innerHeight, 0.1, 1000 );
    camera.rotation.order = 'YXZ';

    const fillLight1 = new THREE.HemisphereLight( 0x4488bb, 0x002244, 0.5 );
    fillLight1.position.set( 2, 1, 1 );
    scene.add( fillLight1 );

    const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.8 );
    directionalLight.position.set( - 5, 25, - 1 );
    directionalLight.castShadow = true;
    directionalLight.shadow.camera.near = 0.01;
    directionalLight.shadow.camera.far = 500;
    directionalLight.shadow.camera.right = 30;
    directionalLight.shadow.camera.left = - 30;
    directionalLight.shadow.camera.top	= 30;
    directionalLight.shadow.camera.bottom = - 30;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    directionalLight.shadow.radius = 4;
    directionalLight.shadow.bias = - 0.00006;
    scene.add( directionalLight );

    const container = document.getElementById( 'world' );

    const renderer = new THREE.WebGLRenderer( {
        antialias: true,
        // logarithmicDepthBuffer: true
    } );
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.VSMShadowMap;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    container.appendChild( renderer.domElement );

    const stats = new Stats();
    stats.domElement.style.position = 'absolute';
    stats.domElement.style.top = '100px';
    stats.domElement.style.right = '0px';
    container.appendChild( stats.domElement );

    const GRAVITY = 30;

    const NUM_SPHERES = 100;
    const SPHERE_RADIUS = 0.2;

    const STEPS_PER_FRAME = 5;

    const sphereGeometry = new THREE.IcosahedronGeometry( SPHERE_RADIUS, 5 );
    const sphereMaterial = new THREE.MeshLambertMaterial( { color: 0xbbbb44 } );

    const spheres = [];
    let sphereIdx = 0;

    for ( let i = 0; i < NUM_SPHERES; i ++ ) {

        const sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
        sphere.castShadow = true;
        sphere.receiveShadow = true;

        scene.add( sphere );

        spheres.push( {
            mesh: sphere,
            collider: new THREE.Sphere( new THREE.Vector3( 0, - 100, 0 ), SPHERE_RADIUS ),
            velocity: new THREE.Vector3()
        } );

    }

    const worldOctree = new Octree();

    const playerCollider = new Capsule( new THREE.Vector3( 0, 0.35, 0 ), new THREE.Vector3( 0, 1, 0 ), 0.35 );

    const playerVelocity = new THREE.Vector3();
    const playerDirection = new THREE.Vector3();

    let playerOnFloor = false;
    let mouseTime = 0;

    const keyStates = {};

    const vector1 = new THREE.Vector3();
    const vector2 = new THREE.Vector3();
    const vector3 = new THREE.Vector3();

    document.addEventListener( 'keydown', ( event ) => {

        keyStates[ event.code ] = true;

    } );

    document.addEventListener( 'keyup', ( event ) => {

        keyStates[ event.code ] = false;

    } );

    container.addEventListener( 'mousedown', () => {

        document.body.requestPointerLock();

        mouseTime = performance.now();

    } );

    document.addEventListener( 'mouseup', () => {

        if ( document.pointerLockElement !== null ) throwBall();

    } );

    document.body.addEventListener( 'mousemove', ( event ) => {

        if ( document.pointerLockElement === document.body ) {

            camera.rotation.y -= event.movementX / 500;
            camera.rotation.x -= event.movementY / 500;

        }

    } );

    window.addEventListener( 'resize', onWindowResize );

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize( window.innerWidth, window.innerHeight );

    }

    function throwBall() {

        const sphere = spheres[ sphereIdx ];

        camera.getWorldDirection( playerDirection );

        sphere.collider.center.copy( playerCollider.end ).addScaledVector( playerDirection, playerCollider.radius * 1.5 );

        // throw the ball with more force if we hold the button longer, and if we move forward

        const impulse = 15 + 30 * ( 1 - Math.exp( ( mouseTime - performance.now() ) * 0.001 ) );

        sphere.velocity.copy( playerDirection ).multiplyScalar( impulse );
        sphere.velocity.addScaledVector( playerVelocity, 2 );

        sphereIdx = ( sphereIdx + 1 ) % spheres.length;

    }

    function playerCollisions() {

        const result = worldOctree.capsuleIntersect( playerCollider );

        playerOnFloor = false;

        if ( result ) {

            playerOnFloor = result.normal.y > 0;

            if ( ! playerOnFloor ) {

                playerVelocity.addScaledVector( result.normal, - result.normal.dot( playerVelocity ) );

            }

            playerCollider.translate( result.normal.multiplyScalar( result.depth ) );

        }

    }

    function updatePlayer( deltaTime ) {

        let damping = Math.exp( - 4 * deltaTime ) - 1;

        if ( ! playerOnFloor ) {

            playerVelocity.y -= GRAVITY * deltaTime;

            // small air resistance
            damping *= 0.1;

        }

        playerVelocity.addScaledVector( playerVelocity, damping );

        const deltaPosition = playerVelocity.clone().multiplyScalar( deltaTime );
        playerCollider.translate( deltaPosition );

        playerCollisions();

        camera.position.copy( playerCollider.end );

    }

    function playerSphereCollision( sphere ) {

        const center = vector1.addVectors( playerCollider.start, playerCollider.end ).multiplyScalar( 0.5 );

        const sphere_center = sphere.collider.center;

        const r = playerCollider.radius + sphere.collider.radius;
        const r2 = r * r;

        // approximation: player = 3 spheres

        for ( const point of [ playerCollider.start, playerCollider.end, center ] ) {

            const d2 = point.distanceToSquared( sphere_center );

            if ( d2 < r2 ) {

                const normal = vector1.subVectors( point, sphere_center ).normalize();
                const v1 = vector2.copy( normal ).multiplyScalar( normal.dot( playerVelocity ) );
                const v2 = vector3.copy( normal ).multiplyScalar( normal.dot( sphere.velocity ) );

                playerVelocity.add( v2 ).sub( v1 );
                sphere.velocity.add( v1 ).sub( v2 );

                const d = ( r - Math.sqrt( d2 ) ) / 2;
                sphere_center.addScaledVector( normal, - d );

            }

        }

    }

    function spheresCollisions() {

        for ( let i = 0, length = spheres.length; i < length; i ++ ) {

            const s1 = spheres[ i ];

            for ( let j = i + 1; j < length; j ++ ) {

                const s2 = spheres[ j ];

                const d2 = s1.collider.center.distanceToSquared( s2.collider.center );
                const r = s1.collider.radius + s2.collider.radius;
                const r2 = r * r;

                if ( d2 < r2 ) {

                    const normal = vector1.subVectors( s1.collider.center, s2.collider.center ).normalize();
                    const v1 = vector2.copy( normal ).multiplyScalar( normal.dot( s1.velocity ) );
                    const v2 = vector3.copy( normal ).multiplyScalar( normal.dot( s2.velocity ) );

                    s1.velocity.add( v2 ).sub( v1 );
                    s2.velocity.add( v1 ).sub( v2 );

                    const d = ( r - Math.sqrt( d2 ) ) / 2;

                    s1.collider.center.addScaledVector( normal, d );
                    s2.collider.center.addScaledVector( normal, - d );

                }

            }

        }

    }

    function updateSpheres( deltaTime ) {

        spheres.forEach( sphere => {

            sphere.collider.center.addScaledVector( sphere.velocity, deltaTime );

            const result = worldOctree.sphereIntersect( sphere.collider );

            if ( result ) {

                sphere.velocity.addScaledVector( result.normal, - result.normal.dot( sphere.velocity ) * 1.5 );
                sphere.collider.center.add( result.normal.multiplyScalar( result.depth ) );

            } else {

                sphere.velocity.y -= GRAVITY * deltaTime;

            }

            const damping = Math.exp( - 1.5 * deltaTime ) - 1;
            sphere.velocity.addScaledVector( sphere.velocity, damping );

            playerSphereCollision( sphere );

        } );

        spheresCollisions();

        for ( const sphere of spheres ) {

            sphere.mesh.position.copy( sphere.collider.center );

        }

    }

    function getForwardVector() {

        camera.getWorldDirection( playerDirection );
        playerDirection.y = 0;
        playerDirection.normalize();

        return playerDirection;

    }

    function getSideVector() {

        camera.getWorldDirection( playerDirection );
        playerDirection.y = 0;
        playerDirection.normalize();
        playerDirection.cross( camera.up );

        return playerDirection;

    }

    function controls( deltaTime ) {

        // gives a bit of air control
        const speedDelta = deltaTime * ( playerOnFloor ? 25 : 8 );

        if ( keyStates[ 'KeyW' ] ) {

            playerVelocity.add( getForwardVector().multiplyScalar( speedDelta ) );

        }

        if ( keyStates[ 'KeyS' ] ) {

            playerVelocity.add( getForwardVector().multiplyScalar( - speedDelta ) );

        }

        if ( keyStates[ 'KeyA' ] ) {

            playerVelocity.add( getSideVector().multiplyScalar( - speedDelta ) );

        }

        if ( keyStates[ 'KeyD' ] ) {

            playerVelocity.add( getSideVector().multiplyScalar( speedDelta ) );

        }

        if ( playerOnFloor ) {

            if ( keyStates[ 'Space' ] ) {

                playerVelocity.y = 15;

            }

        }

    }

    // const loader = new GLTFLoader(loadingManager).setPath( './models/' );

    gltfLoader.load( './models/brasilia.glb', ( gltf ) => {

        scene.add( gltf.scene );

        worldOctree.fromGraphNode( gltf.scene );

        gltf.scene.traverse( child => {

            if ( child.isMesh ) {

                child.castShadow = true;
                child.receiveShadow = true;

                if ( child.material.map ) {

                    child.material.map.anisotropy = 4;

                }

            }

        } );

        // const helper = new OctreeHelper( worldOctree );
        // helper.visible = false;
        // scene.add( helper );

        // const gui = new GUI( { width: 200 } );
        // gui.add( { debug: false }, 'debug' )
        //     .onChange( function ( value ) {
        //
        //         helper.visible = value;
        //
        //     } );

        animate();

    } );

    function teleportPlayerIfOob() {

        if ( camera.position.y <= - 25 ) {

            playerCollider.start.set( 0, 0.35, 0 );
            playerCollider.end.set( 0, 1, 0 );
            playerCollider.radius = 0.35;
            camera.position.copy( playerCollider.end );
            camera.rotation.set( 0, 0, 0 );

        }

    }


    function animate() {

        const deltaTime = Math.min( 0.05, clock.getDelta() ) / STEPS_PER_FRAME;

        // we look for collisions in substeps to mitigate the risk of
        // an object traversing another too quickly for detection.

        for ( let i = 0; i < STEPS_PER_FRAME; i ++ ) {

            controls( deltaTime );

            updatePlayer( deltaTime );

            updateSpheres( deltaTime );

            teleportPlayerIfOob();

        }

        renderer.render( scene, camera );

        stats.update();

        requestAnimationFrame( animate );

    }


}



